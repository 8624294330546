import { render, staticRenderFns } from "./PmSchemaGrid.vue?vue&type=template&id=477bc775&scoped=true"
import script from "./PmSchemaGrid.vue?vue&type=script&lang=js"
export * from "./PmSchemaGrid.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "477bc775",
  null
  
)

export default component.exports