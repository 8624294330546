<template>
	<div>
		<pui-datatable
			:modelName="model"
			:showDeleteBtn="true"
			:actions="actions"
			:modelColumnDefs="columnDefs"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import SchemaActions from './PmSchemaActions';
import { isSuperAdmin } from '../../api/common';

//

export default {
	mixins: [PuiGridDetailMixin],
	name: 'PmSchemaGrid',
	data() {
		return {
			model: 'pmschema',
			actions: SchemaActions.actions,
			masterDetailColumnVisibles: {
				name: true,
				acronym: true,
				datamodelname: true,
				organizationname: true,
				disabled: true
			},
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			}
		};
	},
	methods: {},
	mounted() {
		// eslint-disable-next-line no-unused-vars

		if (!isSuperAdmin(this.session.profiles[0])) {
			this.externalFilter = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }
					/* { field: 'model', op: 'ne', data: constants.NOTIFICATIONS_MODEL } */
				]
			};
		}
	}
};
</script>

<style lang="postcss" scoped></style>
