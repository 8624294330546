import { disableSchemas, enableSchemas } from '@/api/pmschema';

const disableSchema = {
	id: 'disableSchema',
	selectionType: 'multiple',
	label: 'pmschema.actions.disableSchema',
	functionality: 'UPDATE_PMSCHEMA',
	checkAvailability: function (schema) {
		return schema && schema.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmschema.actions.disableSchema');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmschemaid: registries[i].pmschemaid
			});
		}

		const data = await disableSchemas(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableSchema = {
	id: 'enableSchema',
	selectionType: 'multiple',
	label: 'pmschema.actions.enableSchema',
	functionality: 'UPDATE_PMSCHEMA',
	checkAvailability: function (schema) {
		return schema && schema.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmschema.actions.enableSchema');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmschemaid: registries[i].pmschemaid
			});
		}

		const data = await enableSchemas(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableSchema, enableSchema]
};
