import Pui9HttpRequests from '@Pui9Requests';

export async function disableSchemas(schema) {
	const controller = '/pmschema/disableSchemas';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		schema,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableSchemas(schema) {
	const controller = '/pmschema/enableSchemas';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		schema,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}
